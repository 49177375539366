document.addEventListener("DOMContentLoaded", function(event) {

	// $(".geo_btn-region-yes").click(function(){
	// 	const regFixId = $(this).dataset.regid;
	// 	window.location.href = "/?change_region=" + regFixId;
	// });

	const geoBtnRegionYes = document.querySelector(".geo_btn-region-yes");
	if (geoBtnRegionYes) {
		geoBtnRegionYes.addEventListener("click", function() {
			const regFixId = this.dataset.regid;
			window.location.href = "/?change_region=" + regFixId;
		});
	}

	// $(".form-control_select-geo2").change(function(){
	// 	const regId = $(this).val();
	// 	window.location.href = "/?change_region=" + regId;
	// });

	const formControlSelectGeo2 = document.querySelector(".form-control_select-geo2");
	if(formControlSelectGeo2){
		formControlSelectGeo2.addEventListener('change', function(){
			const regId = this.value;
			window.location.href = "/?change_region=" + regId;
		});
	}

	// $(".geo_btn-region-no").click(function(){
	// 	$("#iddivselectregion").show();
	// });

	// $("#idlinkregfix").click(function(){
	// 	$("#iddivselectregion").show();
	// })
});

function geoShowPopup() {
	new Fancybox(
		[{
			src: document.querySelector('.geo__modal-wrapper').innerHTML,
			type: "html"
		}],
		{
			dragToClose: getComputedStyle(document.querySelector('.header__mobile')).display === 'flex' ? false : true, // bug: click to button misses on mobile
			on: {
				reveal: (fancybox, slide) => {
					// $(".form-control_select-geo").change(function(){
					// 	var reg_id = $(this).val();
					// 	window.location.href = "/?change_region=" + reg_id;
					// });

					document.querySelector('.fancybox__content .form-control_select-geo').addEventListener('change', function(){
						const regId = this.value;
						window.location.href = "/?change_region=" + regId;
					});
				}
			}
		}
	);
}